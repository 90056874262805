import React from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import Contact from '../Components/Contact/Contact'

const ContactPage = () => {
  return (
    <>
    <Navbar />
    <Contact />
    <Footer />
    </>
  )
}

export default ContactPage