
import image1 from '../../Assets/before_after1.jpg'
import image2 from '../../Assets/before_after2.jpg'
import image3 from '../../Assets/before_after3.jpg'
import image4 from '../../Assets/before_after4.jpg'
import image5 from '../../Assets/before_after04.jpg'
import image6 from '../../Assets/before_after5.jpg'
import image7 from '../../Assets/before_after6.jpg'
import image8 from '../../Assets/before_after7.jpg'
import image9 from '../../Assets/before_after8.jpg'
import image10 from '../../Assets/before_after9.jpg'
import image11 from '../../Assets/before_after10.jpg'
import image12 from '../../Assets/before_after11.jpg'

export const images = [
  {img: image1, id: 0},
  {img: image2, id: 1},
  {img: image3, id: 2},
  {img: image4, id: 3},
  {img: image5, id: 4},
  {img: image6, id: 5},
  {img: image7, id: 6},
  {img: image8, id: 8},
  {img: image9, id: 9},
  {img: image10, id: 10},
  {img: image11, id: 11},
  {img: image12, id: 12}
]
